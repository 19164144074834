var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',[_c('v-card',[_c('v-card-title',[_vm._v("Good Day "+_vm._s(_vm.$store.state.userProfile.name)+"!")]),_c('v-card-text',{staticClass:"black--text"},[_vm._v(" To begin, click on the navigation list on your left 😊 ")])],1),_c('v-card',{staticClass:"mt-6"},[_c('v-card-title',[_vm._v("SMS Credits Balance")]),_c('v-card-text',{staticClass:"black--text"},[_vm._v("If you have an ongoing project that sends SMS, be sure to keep an eye on the balance. For Mobile Application, sending SMS is disabled except for countries that are enabled. You may change the configuration for Mobile Application "),_c('a',{on:{"click":function($event){return _vm.$router.push({ name: 'MobileSMSGateway' })}}},[_vm._v("HERE")]),_vm._v(".")]),_c('v-data-table',{attrs:{"headers":_vm.smsCreditHeader,"items":_vm.smsCredits,"loading":_vm.smsCreditIsLoading,"loading-text":"Fetching... Please wait","hide-default-footer":""},scopedSlots:_vm._u([{key:"item.value",fn:function({ item }){return [_c('div',{style:(item.value < 5
              ? { color: 'red', fontWeight: 'bold' }
              : { color: 'green' })},[_vm._v(" "+_vm._s(item.value.toFixed(2))+" ")])]}},{key:"item.price",fn:function({ item }){return [_c('div',{domProps:{"innerHTML":_vm._s(_vm.smsPricing[item.name])}})]}}])})],1),_c('v-card',{staticClass:"mt-6"},[_c('v-card-title',[_vm._v("InsightzClub's System Status")]),_c('v-card-text',{staticClass:"black--text"},[_vm._v("Table below shows the current system status. A detail status page which include the past outage is available at "),_c('a',{attrs:{"href":"https://status.insightzclub.com","target":"_blank"}},[_vm._v("https://status.insightzclub.com")]),_vm._v(".")]),_c('v-data-table',{attrs:{"headers":_vm.systemStatusHeader,"items":_vm.systemStatuses,"loading":_vm.systemStatusIsLoading,"loading-text":"Fetching... Please wait","hide-default-footer":""},scopedSlots:_vm._u([{key:"item.description",fn:function({ item }){return [_c('div',{domProps:{"innerHTML":_vm._s(
            _vm.systemStatusDescription[item.attributes.pronounceable_name]
              .description
          )}})]}},{key:"item.outage",fn:function({ item }){return [_c('div',{domProps:{"innerHTML":_vm._s(
            _vm.systemStatusDescription[item.attributes.pronounceable_name].outage
          )}})]}},{key:"item.attributes.status",fn:function({ item }){return [(item.attributes.status == 'up')?_c('v-icon',{attrs:{"color":"green"}},[_vm._v("check")]):_c('div',[_vm._v(_vm._s(item.attributes.status))])]}},{key:"item.attributes.last_checked_at",fn:function({ item }){return [_vm._v(" "+_vm._s(_vm.$moment(item.attributes.last_checked_at).format( "DD-MMMM-YYYY HH:mm:ss" ))+" ")]}}])})],1),_c('v-card',{staticClass:"mt-6"},[_c('v-card-title',[_vm._v("Other System Status")]),_c('v-card-text',{staticClass:"black--text"},[_vm._v("SurveyGizmo / Alchemer system status can be found at "),_c('a',{attrs:{"href":"https://alchemer.statuspage.io/","target":"_blank"}},[_vm._v("https://alchemer.statuspage.io/")]),_vm._v(".")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }