<template>
  <v-container>
    <v-card>
      <v-card-title>Good Day {{ $store.state.userProfile.name }}!</v-card-title>

      <v-card-text class="black--text">
        To begin, click on the navigation list on your left 😊
      </v-card-text>
    </v-card>

    <v-card class="mt-6">
      <v-card-title>SMS Credits Balance</v-card-title>
      <v-card-text class="black--text"
        >If you have an ongoing project that sends SMS, be sure to keep an eye
        on the balance. For Mobile Application, sending SMS is disabled except
        for countries that are enabled. You may change the configuration for
        Mobile Application
        <a @click="$router.push({ name: 'MobileSMSGateway' })">HERE</a
        >.</v-card-text
      >

      <v-data-table
        :headers="smsCreditHeader"
        :items="smsCredits"
        :loading="smsCreditIsLoading"
        loading-text="Fetching... Please wait"
        hide-default-footer
      >
        <template v-slot:item.value="{ item }">
          <div
            :style="
              item.value < 5
                ? { color: 'red', fontWeight: 'bold' }
                : { color: 'green' }
            "
          >
            {{ item.value.toFixed(2) }}
          </div>
        </template>

        <template v-slot:item.price="{ item }">
          <div v-html="smsPricing[item.name]"></div>
        </template>
      </v-data-table>
    </v-card>

    <v-card class="mt-6">
      <v-card-title>InsightzClub's System Status</v-card-title>

      <v-card-text class="black--text"
        >Table below shows the current system status. A detail status page which
        include the past outage is available at
        <a href="https://status.insightzclub.com" target="_blank"
          >https://status.insightzclub.com</a
        >.</v-card-text
      >

      <v-data-table
        :headers="systemStatusHeader"
        :items="systemStatuses"
        :loading="systemStatusIsLoading"
        loading-text="Fetching... Please wait"
        hide-default-footer
      >
        <template v-slot:item.description="{ item }">
          <div
            v-html="
              systemStatusDescription[item.attributes.pronounceable_name]
                .description
            "
          ></div>
        </template>

        <template v-slot:item.outage="{ item }">
          <div
            v-html="
              systemStatusDescription[item.attributes.pronounceable_name].outage
            "
          ></div>
        </template>

        <template v-slot:item.attributes.status="{ item }">
          <v-icon v-if="item.attributes.status == 'up'" color="green"
            >check</v-icon
          >
          <div v-else>{{ item.attributes.status }}</div>
        </template>

        <template v-slot:item.attributes.last_checked_at="{ item }">
          {{
            $moment(item.attributes.last_checked_at).format(
              "DD-MMMM-YYYY HH:mm:ss"
            )
          }}
        </template>
      </v-data-table>
    </v-card>

    <v-card class="mt-6">
      <v-card-title>Other System Status</v-card-title>
      <v-card-text class="black--text"
        >SurveyGizmo / Alchemer system status can be found at
        <a href="https://alchemer.statuspage.io/" target="_blank"
          >https://alchemer.statuspage.io/</a
        >.</v-card-text
      >
    </v-card>
  </v-container>
</template>

<script>
import DataServiceAdmin from "./../services/dataServiceAdmin";

const dataServiceAdmin = new DataServiceAdmin();

export default {
  data() {
    return {
      systemStatusIsLoading: false,
      systemStatuses: [],
      systemStatusHeader: [
        { text: 'Name', value: 'attributes.pronounceable_name', sortable: false },
        { text: 'Status', value: 'attributes.status', sortable: false },
        { text: 'Last Checked', value: 'attributes.last_checked_at', sortable: false },
        { text: 'Description', value: 'description', sortable: false },
        { text: 'What if there is an outage?', value: 'outage', sortable: false },
      ],
      systemStatusDescription: {
        "InsightzClub Website": {
          description: "Our InsightzClub's website at <a href='https://insightzclub.com' target='_blank'>https://insightzclub.com</a>.",
          outage: "Not able to access the website."
        },
        "Mobile API": {
          description: "Backend of the Mobile Application and Browser Extension.",
          outage: "Mobile applcation is not usable and no browser history data can not be syned.",
        },
        "Common API": {
          description: "Microservices for InsightzClub at <a href='https://redirection.insightzclub.com' target='_blank'>https://redirection.insightzclub.com</a>",
          outage: "Not available include Save & Pass Survey Parameter, View SurveyGizmo Sync Status, Sync SurveyGizmo to Buyer Dashboard and Mobile Application, Update Mobile Application survey status, Recognization Engine, Send Push Notification for iOS and Android."
        },
        "Admin / Vendor Dashboard": {
          description: "Admin and Vendor System at <a href='https://admin.insightzclub.com' target='_blank'>https://admin.insightzclub.com</a> & <a href='https://vendor.insightzclub.com<' target='_blank'>https://vendor.insightzclub.com</a>.",
          outage: "Not able to access the website.",
        },
        "Buyer Dashboard": {
          description: "Buyer Dashboard at <a href='https://buyer.insightzclub.com' target='_blank'>https://buyer.insightzclub.com</a>.",
          outage: "Not able to access the website.",
        },
        "Buyer API": {
          description: "Backend of the Buyer System.",
          outage: "Able to access <a href='https://buyer.insightzclub.com' target='_blank'>https://buyer.insightzclub.com</a> but will not able to login or load your projects.",
        },
        "Mobile Dashboard": {
          description: "Part of the frontend of the Mobile Application.",
          outage:  "Not able to access sign up page, account activation page, forgot password page, and rewards redemption page, but the remaining of the Mobile Application works as intended.",
        },
        "Admin / Vendor API": {
          description: "Backend of the Admin and Vendor System.",
          outage: "Able to access the website <a href='https://admin.insightzclub.com' target='_blank'>https://admin.insightzclub.com</a> but not able to login and access other features."
        }
      },
      smsCreditIsLoading: false,
      smsCreditHeader: [
        { text: 'Name', value: 'name', sortable: false },
        { text: 'Account Currency', value: 'currency', sortable: false, align: 'end' },
        { text: 'Balance', value: 'value', sortable: false },
        { text: 'Servicing Countries', value: 'description', sortable: false },
        { text: 'Pricing', value: 'price', sortable: false },
      ],
      smsCredits: [],
      smsPricing: {
        "eSMS": '<a href="https://esms.com.my/sms-pricing/" target="_blank">View</a>',
        "Nexmo": '<a href="https://www.vonage.com.my/communications-apis/sms/pricing/" target="_blank">View</a>',
      }
    }
  },
  mounted() {
    this.getBetterUpTime()
    this.getSmsCredit()
  },
  methods: {
    getSmsCredit() {
      this.$setLoader();
      this.smsCreditIsLoading = true

      dataServiceAdmin.nexmoCreditLookup().then(r => {
        this.smsCredits.push(r.data)

        if (this.smsCredits.length == 2)
          this.smsCreditIsLoading = false
      })

      dataServiceAdmin.esmsCreditLookup().then(r => {
        this.smsCredits.push(r.data)

        if (this.smsCredits.length == 2)
          this.smsCreditIsLoading = false
      })
      this.$disableLoader();
    },
    getBetterUpTime() {
      this.$setLoader();
      this.systemStatusIsLoading = true
      dataServiceAdmin.betterUpTimeProxyPass({ type: "MONITORS" }).then(r => {
        this.systemStatuses = r.data.data
        this.systemStatusIsLoading = false
      })
      this.$disableLoader();
    }
  }
}
</script>

betterUpTimeProxyPass
